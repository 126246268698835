<template>
	<div class="qr-table">
		<div class="d-flex tabs mb-3">
			<v-btn
				v-for="link of links"
				:key="link.to"
				:to="link.to"
				color="white primary--text"
				class="text-capitalize subtitle-2 mr-5"
				link
				depressed
			>
				{{ link.title }}
			</v-btn>
		</div>
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	name: "QrTable",

	data() {
		return {
			links: [
				{ to: "/qr/porch", title: "Домофоны" },
				{ to: "/qr/parkings", title: "Паркинги" },
				{ to: "/qr/barrier", title: "Шлагбаумы" },
				{ to: "/qr/entries", title: "Въезды" },
				{ to: "/qr/gates", title: "Калитки" },
				{ to: "/qr/gates-logs", title: "Калитки (логи)" },
				{ to: "/qr/whitelist", title: "Белые листы" },
			],
		};
	},
};
</script>

<style lang="scss" scoped></style>
